import Log from '@/page/log';
import Account from '@/page/account';
import Stat from '@/page/statistic';
import Adstat from '@/page/adstat';
import Privilege from '@/page/privilege';
import Total from '@/page/total';

const routes = [
  {
    path: '/total',
    component: Total,
    privilege: '1',
    name: '概览',
    icon: 'realtime',
  },
  {
    path: '/stat',
    component: Stat,
    privilege: '2',
    name: '实时统计',
    icon: 'realtime',
  },
  {
    path: '/adstat',
    component: Adstat,
    name: '广告数据',
    icon: 'realtime',
    privilege: '30',
  },
  {
    path: '/log',
    component: Log,
    privilege: '99',
    name: '操作日志',
    icon: 'log',
  },
  {
    path: '/account',
    component: Account,
    privilege: '98',
    name: '账号权限设置',
    icon: 'account',
  },
  {
    path: '/privilege',
    component: Privilege,
    privilege: '97',
    name: '权限管理',
    icon: 'admin',
  },
];

export default routes;

export interface RouteInterface {
  path: string,
  component: any,
  routes?: Array<any>,
  privilege?: string
}
