import {
  Modal, Button, Input, message, TreeSelect
} from 'antd';
import { addRuleGroup, getRuleMap, updateRuleGroup } from '@/common/apis';
import React from 'react';

const { SHOW_PARENT } = TreeSelect;

interface IStates {
  visible: boolean;
  title: string;
  ruleName: string;
  rule: any[];
  ruleMap: [];
  type: string;
  uuid: any;
}

export default class App extends React.Component<any, IStates> {
  constructor(props: IStates) {
    super(props);
    this.state = {
      visible: false,
      title: '',
      ruleName: '',
      rule: [],
      ruleMap: [],
      type: '',
      uuid: '',
    };
  }

  submitAddAccount = async () => {
    // 参数校验
    const { ruleName, rule } = this.state;
    try {
      const { type, uuid } = this.state;
      if (type === 'edit') {
        await updateRuleGroup({
          name: ruleName,
          rule_ids: rule,
          item_uuid: uuid
        });
      } else {
        await addRuleGroup({
          name: ruleName,
          rule_ids: rule,
        });
        message.success('添加权限组成功');
      }
      this.setState({
        visible: false,
      });
      // 列表刷新
      const { onRefresh } = this.props;
      onRefresh();
    } catch (error) {
      console.log(error.message);
    }
  }

  transMapToTreenodes = (data: any) => {
    const mapChilds = (array: any) => {
      const newArray: any = [];
      Object.keys(array).forEach((key: any) => {
        newArray.push({
          title: array[key].name,
          value: array[key].rule_uuid,
          key: array[key].rule_uuid,
          children: mapChilds(array[key].childs),
        });
      });
      return newArray;
    };
    return mapChilds(data);
  }

  getRuleListData = async () => {
    // 参数校验
    try {
      const param = {
        parent_uuid: '',
        page: 1,
        num: 999
      };
      const res = await getRuleMap(param);
      this.setState({
        ruleMap: this.transMapToTreenodes(res.data.rules)
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  showModal = (options: any) => {
    this.setState({
      visible: true,
      title: options.title || '',
      type: options.type || '',
      ruleName: ''
    });
    if (options.type === 'edit') {
      const arr = options.data.rules;
      const newArray: any[] = [];
      arr.forEach((element: any) => {
        newArray.push(element.rule_uuid);
      });
      this.setState({
        rule: newArray,
        ruleName: options.data.name,
        uuid: options.data.uuid || '',
      });
    }
    this.getRuleListData();
  };

  handleOk = () => {
    this.submitAddAccount();
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleChange = (e: any) => {
    const { value, name } = e.target;
    const obj: any = {};
    obj[name] = value;
    this.setState(obj);
  };

  handleRuleChange = (e: any) => {
    const { value, name } = e.target;
    const obj: any = {};
    obj[name] = value;
    this.setState(obj);
  };

  onChange = (value: any) => {
    this.setState({ rule: value });
  }

  render() {
    const {
      visible, title, rule, ruleMap, ruleName
    } = this.state;
    return (
      <>
        <Modal
          title={title}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              确定
            </Button>,
          ]}
        >
          <div className="modal-input">
            <span className="label">名称</span>
            <Input
              placeholder="请输入权限组名称"
              size="large"
              name="ruleName"
              value={ruleName}
              onChange={(e) => this.handleChange(e)}
            />
          </div>
          <div className="modal-input">
            <span className="label">权限</span>
            <TreeSelect
              treeData={ruleMap}
              value={rule}
              onChange={this.onChange}
              treeCheckable="true"
              showCheckedStrategy={SHOW_PARENT}
            />
          </div>
        </Modal>
      </>
    );
  }
}
