// 登录账户
export const LOGIN_USER_INFO = 'LOGIN_USER_INFO';
export type LOGINUSERINFO_TYPE = typeof LOGIN_USER_INFO;

// 登录状态
export const LOGIN_STATE = 'LOGIN_STATE';
export type LOGINSTATE_TYPE = typeof LOGIN_STATE;

export const USER_PRIVILEGE = 'USER_PRIVILEGE';
export type USERPRIVILEGE_TYPE = typeof USER_PRIVILEGE;

// 登录账号数据结构
export const ACCOUNTDATA = {
  account_type: '',
  account_type_text: '',
  name: '',
  uuid: '',
  rule_groups: [],
};

export const STATEDATA = {
  isLogin: ''
};
