export const lineChart: any = {
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    data: [],
    type: "scroll",
    width: '800px',
  },
  xAxis: {
    type: 'category',
    data: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
      11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      21, 22, 23],
    boundaryGap: false,
    axisLabel: {
      interval: 'auto',
      rotate: 0,
      align: 'center',
    },
  },
  yAxis: {
    type: 'value',
    position: 'right',
    axisTick: {
      inside: true
    },
    axisLine: {
      lineStyle: {
        color: '#ffffff'
      }
    }
  },
  series: [{
    data: [],
    type: 'line',
  }],
};

export const barChart = {
  tooltip: {
    trigger: 'axis',
  },
  xAxis: {
    type: 'category',
    data: [],
    axisLabel: {
      interval: 'auto',
      rotate: 0,
      align: 'center',
    },
  },
  yAxis: {
    type: 'value',
  },
  series: [{
    data: [],
    type: 'bar',
  }],
};

export const chartTheme = {
  color: [
    '#FFB03A',
    '#45DDEA',
    '#edafda',
    '#93b7e3',
    '#a5e7f0',
    '#cbb0e3',
  ],
  backgroundColor: 'rgba(0,0,0,0)',
  textStyle: {},
  title: {
    textStyle: {
      color: '#516b91',
    },
    subtextStyle: {
      color: '#93b7e3',
    },
  },
  line: {
    itemStyle: {
      normal: {
        borderWidth: '2',
      },
    },
    lineStyle: {
      normal: {
        width: '2',
      },
    },
    symbolSize: '6',
    symbol: 'emptyCircle',
    smooth: true,
  },
  bar: {
    itemStyle: {
      normal: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
      emphasis: {
        barBorderWidth: 0,
        barBorderColor: '#ccc',
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#cccccc',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#999999',
      },
    },
    splitLine: {
      show: false,
      lineStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#cccccc',
      },
    },
    axisTick: {
      show: false,
      lineStyle: {
        color: '#333',
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: '#999999',
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: [
          '#eeeeee',
        ],
      },
    },
  },
};
