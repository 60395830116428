import React, {
  FC, useEffect, useState
} from 'react';
import TopCards from './top_card';
import PageLayout from '@/page/layout/index';
import './index.scss';

const Page: FC = () => {
  // const [cardData, setCardData] = useState({
  //   day: '--',
  //   day_rate: '--',
  //   hour: '--',
  //   hour_rate: '--',
  //   // month_rate: '--',
  //   // week_rate: '--'
  // });

  // const getDateLineData = async () => {
  //   try {
  //     setCardData({
  //       day: '1',
  //       day_rate: '2',
  //       hour: '3',
  //       hour_rate: '4',
  //     });
  //   } catch (error) {
  //   }
  // };

  // useEffect(() => {
  //   getDateLineData();
  // })

  // const getFootArrowForward = (rate: any) => (
  //   +rate > 0 ? 'up' : +rate === 0 ? 'stay' : 'down'
  // );

  return (
    <PageLayout>
      <div>
        <TopCards />
        {/* <div className="chartData">
          <div className="data-card">
            <div className="data-title">今日新增用户数</div>
            <div className="data-num">{cardData.day}</div>
            <div className="data-tend">
              <div>
                <div className="foot">
                  <i className={`icon ${getFootArrowForward(cardData.day_rate)}`} />
                  {cardData.day_rate}%
                </div>
                <span>日环比</span>
              </div>
            </div>
          </div>
          <div className="data-card">
            <div className="data-title">上一小时新增用户数</div>
            <div className="data-num">{cardData.hour}</div>
            <div className="data-tend">
              <div>
                <div className="foot">
                  <i className={`icon ${getFootArrowForward(cardData.hour_rate)}`} />
                  {cardData.hour_rate}%
                </div>
                <span>时环比</span>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </PageLayout>
  );
};
export default Page;
