/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form, Input, Button, Checkbox, Tabs, Spin, message
} from 'antd';
import {
  loginWithPwd,
  getQrInfo,
  getQrImg
} from '@/common/apis';
import { isLoginLoop, clearLoop } from '@/common/auth';
import { useDispatch } from 'react-redux';

import './index.scss';
import { LOGIN_USER_INFO, LOGIN_STATE } from '@/store/types/login';

const { TabPane } = Tabs;

const layout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 13 },
};
const tailLayout = {
  wrapperCol: { offset: 7, span: 16 },
};

const App: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [ltoken, setLToken] = useState('');

  const loginSuccess = (userInfo: any) => {
    const dispatchData = { type: LOGIN_USER_INFO, data: userInfo };
    const dispatchLoginState = {
      type: LOGIN_STATE,
      login: {
        isLogin: userInfo.name,
      },
    };
    dispatch(dispatchLoginState);
    dispatch(dispatchData);
    localStorage.setItem('isLogin', userInfo.name);
    history.push('/login');
  };

  const doLoopValid = async (token: string) => {
    try {
      isLoginLoop(token, 5000, true, (res: any) => {
        const userInfo = res.data.user;
        loginSuccess(userInfo);
      });
    } catch (error) {
      // console.log(error);
    }
  };

  const getQrInfoData = async () => {
    try {
      const { data } = await getQrInfo();
      setLToken(data.token);
      doLoopValid(data.token);
    } catch (error) {
      console.log(error.message);
    }
  };

  const submitLogin = async (values: any) => {
    // 邮箱密码登录
    setLoading(true);
    try {
      const { data } = await loginWithPwd(values);
      // remember me 功能
      if (values.remember) {
        console.log('remember me');
      }
      const { user } = data;
      loginSuccess(user);
      history.push('/login');
      
    } catch (error) {
      setLoading(false);
      message.error(error.message);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    getQrInfoData();
  }, []);

  const onChangeTab = (value: any) => {
    if (+value === 1) {
      doLoopValid(ltoken);
    } else {
      clearLoop();
    }
  }

  return (
    <div className="logn-wrap">
      {/* <div className="logo" /> */}
      <div className="login-panel">
        <Spin spinning={loading} delay={100}>
          <Tabs type="card" onChange={onChangeTab}>
            <TabPane tab="App扫码登录" key="1">
              <div className="qrcode-box">
                {ltoken ? (
                  <img
                    className="qrcode-img"
                    src={`${getQrImg}?token=${ltoken}`}
                    alt="logo"
                  />
                ) : (
                  '获取二维码失败'
                )}
              </div>
            </TabPane>
            <TabPane tab="账号密码登录" key="2">
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={submitLogin}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="账号"
                  name="email"
                  rules={[{ required: true, message: 'Please input your email!' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="密码"
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password />
                </Form.Item>

                {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                  <Checkbox>Remember me</Checkbox>
                </Form.Item> */}

                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    登录
                  </Button>
                </Form.Item>
              </Form>
            </TabPane>
          </Tabs>
        </Spin>
      </div>
    </div>
  );
};

export default App;
