import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

import 'normalize.css';
import '@/style/index.scss';
import '@/style/layout.scss';
import '@/style/media.scss';

import Sider from '@/page/layout/Sider';
import Header from '@/page/layout/Header';
import Footer from '@/page/layout/Footer';

const PageLayout: FC = (props) => {
  const { children } = props;
  const { Content } = Layout;
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider />
      <Layout className="site-layout">
        <Header />
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ minHeight: 360 }}>
            {children}
          </div>
        </Content>
        <Footer />
      </Layout>
    </Layout>
  );
};

PageLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default PageLayout;
