import React, { FC, useState } from 'react';
import {
  Layout, Avatar, Menu, Dropdown, PageHeader
} from 'antd';
import { useSelector } from 'react-redux';
import {
  withRouter, useHistory
} from 'react-router-dom';
import { getImageURL } from '@/common/image_utils';
import { logout } from '@/common/apis';
import store from '../../store'
import { LOGIN_STATE } from '@/store/types/login'

const titleConfig: any = {
  '/account': '账户权限设置',
  '/stat': '实时统计',
  '/log': '操作日志',
  '/privilege': '权限管理',
};

const HeaderComponent: FC = (history: any) => {
  const titleStr = titleConfig[history.location.pathname];
  const data = useSelector((state: any) => state);
  const routeHistory = useHistory();
  const { Header } = Layout;
  const [state] = useState({
    account: data.account,
  });

  const doLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.log(error.message);
    } finally {
      store.dispatch({
        type: LOGIN_STATE,
        login: {
          isLogin: false,
        },
      })
      localStorage.removeItem('stat_query');
      localStorage.removeItem('ad_stat_query');
      localStorage.setItem('isLogin', '');
      routeHistory.push('/login');
    }
  };

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      <div className="header-content">
        <PageHeader
          className="site-page-header"
          title={titleStr}
        />
      </div>
      <Dropdown
        overlay={
          (
            <Menu>
              <Menu.Item>
                <div onClick={doLogout}>
                  退出登录
                </div>
              </Menu.Item>
            </Menu>
          )
        }
        placement="bottomCenter"
      >
        <div className="header-avatar">
          <Avatar src={getImageURL(state.account.avatar_uuid, 60)} />
          <span>{state.account.name}</span>
        </div>
      </Dropdown>
    </Header>
  );
};

const HeaderLayout = withRouter(HeaderComponent);
export default HeaderLayout;
