
export const transData = (data: any, dict: any) => {
  // 横坐标
  const series: any = [];
  // 纵坐标集合
  let flatValues: any = [];

  // 将数据展成一维存入map
  const getFlatRows = (data: any, key: string) => {
    let flatData: any = [];
    
    data.rows.forEach((item: any) => {
      // 遍历每一个条件
      // 获取扁平化键值
      let keyArray: any = [];
      if (item.by_values) {
        keyArray = [key, item.by_values.join('&')];
      } else {
        keyArray = [key];
      }
      
      data.measures.forEach((measure: any, index: number) => {
        // 遍历指标
        if (index > 0) {
          keyArray.pop();
        }
        keyArray.push(dict[measure]);
        let tempArray: any = [];
        item.values.forEach((val: any) => {
          // 遍历值数组
          tempArray.push(val[index]);
        });
        let temp: any = {};
        temp[keyArray.join('#')] = tempArray;
        flatData.push(temp);
      });
    });
    return flatData;
  }

  data.forEach((element: any) => {
    if (element.series && element.series.length > 0) {
      element.series.forEach((item: any, i: number) => {
        item = item.split('-').slice(1).join('-');
        if (series[i]) {
          series[i] = `${series[i]}/${item}`
        } else {
          series.push(item);
        }
      })
      let compareFlag = `${element.series[0]}/${element.series[element.series.length - 1]}`;
      flatValues = flatValues.concat(getFlatRows(element, compareFlag));
    }
  });
  return {x: series, y: flatValues};
}

export const transTableData = (data: any, dict: any) => {
  // 表格数据结构
  // {
  //   id...: [
  //     {
  //       measure: [],
  //       measure: []
  //     }
  //     {
  //       measure: [],
  //       measure: []
  //     }
  //   ]
  // }
  let resObj: any = {};

  if (!data) return resObj;

  data.forEach((itemByTime: any, index: number) => {
    if (itemByTime.rows && itemByTime.rows.length > 0) {
      itemByTime.rows.forEach((itemByValue: any, i: number) => {
        let measureObj: any = {}
        itemByTime.measures.forEach((measure: any, j: number) => {
          let flatArray: any = [];
          itemByValue.values.forEach((item: any) => {
            flatArray.push(item[j])
          });
          measureObj[dict[measure]] = flatArray;
        });
        let key = itemByValue.by_values ? itemByValue.by_values.join('&') : '--';
        resObj[key] = resObj[key] || [];
        resObj[key][index] = measureObj;
      });
    }
  });
  return resObj;
}
