import { createBrowserHistory } from 'history';
import axios from 'axios';
import isFunction from 'lodash/isFunction';
import { message } from 'antd';
import store from '../store';
import context from './context'
import { USER_PRIVILEGE, LOGIN_STATE } from '../store/types/login';

export const API_BASE_VERSION = '2.11.1';
export const APP_VERSION = '1.0.0';
export const API_HOST = context.isDebug() || !context.forceInteralServer()
  ? 'beta.insight.skyplatanus.com' : 'insight.skyplatanus.com';

const address = {
  login: '/login',
  home: '/stat'
};

export const crucioClient = axios.create({
  baseURL: '/api',
  timeout: 30000,
  withCredentials: true,
  headers: {
    'X-Crucio-User-Agent': `Crucio/${API_BASE_VERSION} (CrucioWebApp/${APP_VERSION}; CrucioEditor)`,
  },
});

export const crucioOtherClient = axios.create({
  baseURL: '/api',
  timeout: 30000,
  withCredentials: true,
  responseType: 'blob',
  headers: {
    'X-Crucio-User-Agent': `Crucio/${API_BASE_VERSION} (CrucioWebApp/${APP_VERSION}; CrucioEditor)`,
  },
});

const generateCommonResponseHandler = ({ responseHandler }) => ((response) => {
  const rawData = response.data;
  if (isFunction(responseHandler)) {
    responseHandler(response);
  }
  response.timestamp = rawData.timestamp;
  response.code = rawData.code;
  response.data = rawData.data;
  response.privileges = rawData.privileges;
  if (response.code !== 0) {
    const error = new Error(rawData.msg || 'Unknown server error.');
    return Promise.reject(error, response);
  }
  return response;
});

// 403
const commonResponseErrorHandler = (error) => {
  switch (error.response.status) {
    case 403: 
      message.error('暂无权限！');
      break;
    case 401:
      const history = createBrowserHistory();
      store.dispatch({
        type: LOGIN_STATE,
        login: {
          isLogin: false,
        },
      });
      localStorage.setItem('isLogin', '');
      setTimeout(() => {
        history.push(address.login);
      }, 1000);
      break;
    default:
      return Promise.reject(error);
  }
};

crucioClient.interceptors.response.use(generateCommonResponseHandler({
  responseHandler(response) {
    const { data } = response;
    store.dispatch({
      type: USER_PRIVILEGE,
      privilege: data.privileges,
    });
    localStorage.setItem('privileges', JSON.stringify(data.privileges));
    const history = createBrowserHistory();
    if (data.code === 1) {
      store.dispatch({
        type: LOGIN_STATE,
        login: {
          isLogin: false,
        },
      });
      localStorage.setItem('isLogin', '');
      setTimeout(() => {
        history.push(address.login);
      }, 1000);
    }
    if (data.code === 403) {
      message.error('暂无权限！');
      setTimeout(() => {
        history.push(address.home);
      }, 1000);
    }
  },
}), commonResponseErrorHandler);

export default crucioClient;
