import { AnyAction, combineReducers } from 'redux';
// import { LoginAction } from '../actions';
import { LOGIN_STATE, LOGIN_USER_INFO, USER_PRIVILEGE } from '../types/login';


const privileges = JSON.parse(localStorage.getItem('privileges') || '[]') || [];
const isLogin = localStorage.getItem('isLogin') || '';
const initAccount = {};
const initLogin = {
  isLogin,
};
const initPrivileges = privileges;


function account(state = initAccount, action: AnyAction): Object {
  switch (action.type) {
    case LOGIN_USER_INFO:
      return {
        ...Object.assign(initAccount, action.data)
      };
    default:
      return state;
  }
}

function login(state = initLogin, action: AnyAction): Object {
  switch (action.type) {
    case LOGIN_STATE:
      return {
        ...Object.assign(initLogin, action.login)
      };
    default:
      return state;
  }
}

function privilege(state = initPrivileges, action: AnyAction): Object {
  switch (action.type) {
    case USER_PRIVILEGE:
      return {
        ...Object.assign(initPrivileges, action.privilege),
      };
    default:
      return state;
  }
}

export default combineReducers({
  account,
  login,
  privilege,
});
