import React from 'react';
import {
  BrowserRouter as Router, Route, Switch, Redirect
} from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'

import routes, { RouteInterface } from '@/router/index';

import Login from './login'

import Log from '@/page/log';
import Account from '@/page/account';
import Stat from '@/page/statistic';
import Adstat from '@/page/adstat';
import Privilege from '@/page/privilege';
import Total from '@/page/total';

const RouteWithSubRoutes = (route: RouteInterface, index: number) => (
  <Route
    key={index}
    path={route.path}
    render={(props) => (
      <route.component {...props} routes={route.routes} />
    )}
  />
);



const menu = () => {
  let privList = JSON.parse(localStorage.getItem('privileges') || '') || [];
  privList = Object.keys(privList);
  return routes.map((route: RouteInterface, i: number) => {
    const intersection = privList.includes(route.privilege);
    return intersection ? (RouteWithSubRoutes(route, i)) : '';
  })
  // return routes.map((route: RouteInterface, i: number) => RouteWithSubRoutes(route, i))
}

const Links = () => {
  let privList = JSON.parse(localStorage.getItem('privileges') || '') || [];
  privList = Object.keys(privList).length > 0 ? Object.keys(privList)[0] : '';
  let directComponent = Stat;
  switch (privList) {
    case '1':
      directComponent = Total;
      break;
    case '2':
      directComponent = Stat;
      break;
    case '30':
      directComponent = Adstat;
      break;
    case '97':
      directComponent = Privilege;
      break;
    case '98':
      directComponent = Account;
      break;
    case '99':
      directComponent = Log;
      break;
  }
  
  return  (
    <Switch>
      {menu()}
      <Route path="/" component={directComponent} />
    </Switch>
  )
}

const getDefaultDirect = () => {
  let menus: any = menu();
  let path = '';
  try {
    menus.forEach((element: any) => {
      if (element) {
        path = element.props.path;
        throw Error();
      }
    });
  } catch (error) {}
  return path;
}

const Root = (props: any) => {
  const { login } = props;
  const { isLogin } = login;

  return (
    <Router>
      <Switch>
        <Route
          path={'/login'}
          render={() => (!isLogin ? (
            <Route path={'/login'} component={Login} />
          ) : (
            <Redirect to={getDefaultDirect()} />
          ))
          }
        />
        <Route path="/" render={() => (isLogin ? Links() : <Redirect to={'/login'} />)} />
      </Switch>
    </Router>
  );
}

Root.propTypes = {
  login: PropTypes.object.isRequired,
}

const mapStateToProps = (state: any) => ({
  login: state.login,
})

export default connect(mapStateToProps)(Root);
