import React, { FC } from 'react';
import { Layout } from 'antd';

const FooterLayout: FC = () => {
  const { Footer } = Layout;

  return (
    <Footer style={{ textAlign: 'center' }} />
  );
};

export default FooterLayout;
