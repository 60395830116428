import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store, { persistor } from '@/store';

import Root from './page/root'
import * as serviceWorker from './serviceWorker'

import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { PersistGate } from 'redux-persist/integration/react';


import moment from 'moment';
import 'moment/locale/zh-cn';

moment.locale('zh-cn');

ReactDOM.render(
  <ConfigProvider locale={zh_CN}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <Root />
      </PersistGate>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
