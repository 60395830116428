import React, {
  FC, useEffect, useState, useRef
} from 'react';
import {
  Table, Button, Space, message
} from 'antd';
import { getPrivilegeList, deleteRuleGroup } from '@/common/apis';
import PageLayout from '@/page/layout/index';
import AddAdminModal from './addPrivilegeModal';
import './index.scss';

const Page: FC = () => {
  const modalRef: any = useRef();

  const [data, setData] = useState({
    list: [],
    num: 0,
    page: 1,
    total: 0,
  });
  const [param, setParam] = useState({
    type: 1,
    page: 1,
    num: 10
  });
  const [loadingState, setLoadingState] = useState(false);

  const getPrivilegeListData = async () => {
    setLoadingState(true);
    try {
      const res = await getPrivilegeList({
        account_type: param.type,
        page: param.page,
        num: param.num,
      });

      const {
        rule_groups: listData,
        page: pageCount,
        num: numCount,
        total: totalCount
      } = res.data;

      listData.map((item : any) => (Object.assign(item, {
        key: item.uuid
      })));

      setData({
        list: listData,
        page: pageCount,
        num: numCount,
        total: totalCount
      });

      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      message.error(error.message);
    }
  };

  function changePagination(pageCount: number | undefined, pageSize: number | undefined) {
    setParam(Object.assign(param, { page: pageCount, num: pageSize }));
    getPrivilegeListData();
  }

  function showAddModal(editFlag?: any, record?: any) {
    const modalNode = modalRef.current;
    if (modalNode !== null) {
      modalNode.showModal({
        title: editFlag === 'edit' ? '修改权限组' : '添加权限组',
        type: editFlag,
        data: record
      });
    }
  }

  const renderContent = (value: any) => {
    const obj = {
      children: value,
      props: {},
    };
    return obj;
  };

  function updateRuleGroup(record: any) {
    console.log(record);
    showAddModal('edit', record);
  }

  async function delRuleGroup(record: any) {
    try {
      setLoadingState(true);
      await deleteRuleGroup({
        uuid: record.uuid
      });
      message.success('删除权限组成功');
      getPrivilegeListData();
      setLoadingState(false);
    } catch (error) {
      message.success(error.message);
      setLoadingState(false);
    }
  }

  const columns = [
    {
      title: 'UUID',
      dataIndex: 'uuid',
      render: renderContent,
    },
    {
      title: '名称',
      dataIndex: 'name',
      render: renderContent,
    },
    {
      title: '数据权限',
      dataIndex: 'rules',
      render: (value: any) => {
        let ruleStr = '';
        value.forEach((item: any) => {
          ruleStr = `${ruleStr}${ruleStr !== '' ? ',' : ''}${item.name}`;
        });
        return ruleStr;
      },
    },
    {
      title: '操作',
      dataIndex: 'opt',
      render: (text: any, record: any) => (
        <Space size="middle">
          <a style={{ color: 'red' }} onClick={() => { updateRuleGroup(record); }}>修改权限组</a>
          <a style={{ color: 'red' }} onClick={() => { delRuleGroup(record); }}>删除</a>
        </Space>
      )
    }
  ];

  useEffect(() => {
    getPrivilegeListData();
  }, []);

  return (
    <PageLayout>
      <div className="page-wrap">
        <h3>权限组列表</h3>
        <div className="add-admin-btn">
          <Button type="primary" onClick={() => { showAddModal(); }}>添加权限组</Button>
        </div>
        <br />
        <Table
          rowKey="uuid"
          loading={loadingState}
          columns={columns}
          dataSource={data.list}
          bordered
          pagination={{
            onChange: changePagination,
            total: data.total,
            pageSize: 10,
          }}
        />
        <AddAdminModal ref={modalRef} onRefresh={getPrivilegeListData} />
      </div>
    </PageLayout>
  );
};

export default Page;
