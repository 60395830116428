import isFunction from 'lodash/isFunction';
// import { message } from 'antd';
import { loopValid } from './apis';

export const isLoginLoop = async (_token, delay, loop, callback) => {
  let loop_timmer = setTimeout(async () => {
    try {
      const res = await loopValid({ token: _token });
      const data = res;
      if (data && isFunction(callback) && data.code === 0) {
        localStorage.removeItem('loop_timmer');
        callback(data);
      } else if (loop) {
        isLoginLoop(_token, delay, loop, callback);
      }
    } catch (error) {
      // message.error(error.message);
      if (loop) {
        isLoginLoop(_token, delay, loop, callback);
      }
    }
  }, delay);
  localStorage.setItem('loop_timmer', loop_timmer);
};

export const clearLoop = () => {
  clearTimeout(localStorage.getItem('loop_timmer'));
  localStorage.removeItem('loop_timmer')
}

export default isLoginLoop;
