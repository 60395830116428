import {
  Modal, Button, Input, message, Select
} from 'antd';
import {
  addAccount, getPrivilegeList, getDepartmentList, getDepartmentMemberList, getMarketList, addMarketAccount
} from '@/common/apis';
import React from 'react';

const { Option } = Select;

const typelist = [{
  value: 1,
  name: '管理员'
}, {
  value: 20,
  name: '分析师'
}, {
  value: 30,
  name: '渠道管理员'
}, {
  value: 40,
  name: '渠道'
}];

export default class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      title: '',
      privilegeList: [],
      departmentList: [],
      memberList: [],
      level1Options: [],
      level2Options: [],
      level1: [],
      level2: [],
      type: 1,
      inviteCode: '',
      departmentId: '',
      wechatId: '',
      ruleGroupId: [],
      email: '',
      password: '',
      nickname: '',
    };
  }

  submitAddAccount = async () => {
    // 参数校验
    const {
      inviteCode, wechatId, type, ruleGroupId, email: pEmail, password: pPassword, level2, nickname: pNickname
    } = this.state;
    try {
      const param = {
        account_type: type,
        group_ids: ruleGroupId
      };
      if (type !== 40) {
        await addAccount(Object.assign(param, {
          wechat_id: wechatId,
          invite_code: inviteCode,
        }));
      } else {
        await addMarketAccount(Object.assign(param, {
          email: pEmail,
          password: pPassword,
          market_uuids: level2,
          nickname: pNickname
        }));
      }

      message.success('添加用户成功');
      this.setDefaultValue();
      this.setState({
        visible: false,
      });
      // 列表刷新
      const { onRefresh } = this.props;
      onRefresh();
    } catch (error) {
      message.error(error.message);
    }
  }

  getMarketListData = async (level: number) => {
    try {
      const { level1 } = this.state;
      const parentUuid = +level === 1 ? 0 : level1;

      const res = await getMarketList({
        app_uuid: 1,
        os: 1,
        parent_uuid: parentUuid,
        page: 1,
        num: 999,
      });
      if (level === 1) {
        this.setState({
          level1Options: res.data.markets
        });
      } else {
        this.setState({
          level2Options: res.data.markets
        });
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  showModal = (options: any) => {
    this.setState({
      visible: true,
      title: options.title || ''
    });
    this.getDepartmentListData();
  };

  getPrivilegeListData = async () => {
    const { type } = this.state;
    try {
      const res = await getPrivilegeList({
        account_type: type,
        page: 1,
        num: 999,
      });

      const {
        rule_groups: listData
      } = res.data;

      this.setState({
        privilegeList: listData,
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  getDepartmentListData = async () => {
    try {
      const res = await getDepartmentList();

      const {
        departments: listData
      } = res.data;

      this.setState({
        departmentList: listData,
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  getDepartmentMemberListData = async () => {
    try {
      const { departmentId } = this.state;
      const res = await getDepartmentMemberList({
        id: departmentId,
      });

      const {
        members: listData
      } = res.data;

      this.setState({
        memberList: listData,
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  handleOk = () => {
    this.submitAddAccount();
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleChange = (e: any) => {
    const { value, name } = e.target;
    const obj: any = {};
    obj[name] = value;
    this.setState(obj);
  }

  handleTypeChange = (value: any) => {
    this.setState({
      type: value,
    }, () => {
      this.setDefaultValue();
      this.getPrivilegeListData();
      this.getMarketListData(1);
    });
  }

  handleDepartmentChange = (value: any) => {
    this.setState({
      departmentId: value
    }, () => {
      this.getDepartmentMemberListData();
    });
  }

  handleMemberChange = (value: any) => {
    this.setState({
      wechatId: value
    });
  }

  handleRuleChange = (value: any) => {
    this.setState({
      ruleGroupId: value
    });
  }

  handleLevel1Change = (value: any) => {
    this.setState({
      level1: value
    }, () => {
      this.getMarketListData(2);
    });
  };

  handleLevel2Change = (value: any) => {
    this.setState({
      level2: value
    });
  };

  setDefaultValue = () => {
    this.setState({
      level1: [],
      level2: [],
      inviteCode: '',
      departmentId: [],
      wechatId: '',
      ruleGroupId: [],
      email: '',
      password: '',
      nickname: '',
    })
  }

  departmentAccountForm = () => {
    const { memberList, departmentList } = this.state;
    return (
      <>
        <div className="modal-input">
          <span className="label">喵牌</span>
          <Input
            placeholder="请输入喵牌"
            size="large"
            name="inviteCode"
            defaultValue={this.state.inviteCode}
            value={this.state.inviteCode}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className="modal-input">
          <span className="label">所属部门</span>
          <Select
            placeholder="请选择所属部门"
            style={{ width: 120 }}
            defaultValue={this.state.departmentId}
            value={this.state.departmentId}
            onChange={(e) => this.handleDepartmentChange(e)}
          >
            {departmentList.map((item: any) => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </div>
        <div className="modal-input">
          <span className="label">企业微信</span>
          <Select
            placeholder="请选择企业微信用户"
            style={{ width: 120 }}
            defaultValue={this.state.wechatId}
            value={this.state.wechatId}
            onChange={(e) => this.handleMemberChange(e)}
          >
            {memberList.map((item: any) => (
              <Option key={item.wechat_id} value={item.wechat_id}>{item.name}</Option>
            ))}
          </Select>
        </div>
      </>
    );
  }

  marketAccountForm = () => {
    const { level1Options, level2Options } = this.state;
    return (
      <>
        <div className="modal-input">
          <span className="label">昵称</span>
          <Input
            placeholder="请输入昵称"
            size="large"
            name="nickname"
            defaultValue={this.state.nickname}
            value={this.state.nickname}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className="modal-input">
          <span className="label">邮箱</span>
          <Input
            placeholder="请输入邮箱账户"
            size="large"
            name="email"
            defaultValue={this.state.email}
            value={this.state.email}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className="modal-input">
          <span className="label">密码</span>
          <Input
            placeholder="请输入初始密码"
            size="large"
            name="password"
            defaultValue={this.state.password}
            value={this.state.password}
            onChange={(e) => this.handleChange(e)}
          />
        </div>
        <div className="modal-input">
          <span className="label">所属渠道</span>
          <Select
            style={{ width: 50 }}
            placeholder="一级渠道"
            allowClear={Boolean(true)}
            defaultValue={this.state.level1}
            value={this.state.level1}
            onChange={(e) => { this.handleLevel1Change(e); }}
          >
            {
              level1Options.map((item: any) => (
                <Option key={+item.uuid} value={item.uuid}>{item.name}</Option>
              ))
            }
          </Select>
        </div>
        <div className="modal-input">
          <span className="label">二级渠道</span>
          <Select
            style={{ width: 100 }}
            placeholder="二级渠道"
            allowClear={Boolean(true)}
            mode="multiple"
            defaultValue={this.state.level2}
            value={this.state.level2}
            onChange={(e) => { this.handleLevel2Change(e); }}
          >
            {
              level2Options.map((item: any) => (
                <Option key={+item.uuid} value={item.uuid}>{item.name}</Option>
              ))
            }
          </Select>
        </div>
      </>
    );
  }

  render() {
    const {
      visible, title, type, privilegeList
    } = this.state;
    return (
      <>
        <Modal
          title={title}
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              确定
            </Button>,
          ]}
        >
          <div className="modal-input">
            <span className="label">角色</span>
            <Select placeholder="请选择角色类型" style={{ width: 120 }} onChange={(e) => this.handleTypeChange(e)}>
              {
                typelist.map((item: any) => (
                  <Option key={item.value} value={item.value}>{item.name}</Option>
                ))
              }
            </Select>
          </div>
          {
            +type === 40 ? this.marketAccountForm() : this.departmentAccountForm()
          }
          <div className="modal-input">
            <span className="label">权限组</span>
            <Select
              placeholder="请选择权限组"
              style={{ width: 120 }}
              defaultValue={this.state.ruleGroupId}
              value={this.state.ruleGroupId}
              onChange={(e) => this.handleRuleChange(e)}
              mode="multiple"
              allowClear
            >
              {
                privilegeList.map((item: any) => (
                  <Option key={item.uuid} value={item.uuid}>{item.name}</Option>
                ))
              }
            </Select>
          </div>
        </Modal>
      </>
    );
  }
}
