import React, { FC, useEffect, useState } from 'react';
import { Table, Select } from 'antd';
import { getLogList, getLogTypeList } from '@/common/apis';
import PageLayout from '@/page/layout/index';

const { Option } = Select;

interface selectItem {
  name: string,
  value: number
}

const Page: FC = () => {
  const renderContent = (value: any) => {
    const obj = {
      children: value || '--',
      props: {},
    };
    return obj;
  };

  const columns = [
    {
      key: 'admin_uuid',
      title: 'UUID',
      dataIndex: 'admin_uuid',
      render: renderContent,
    },
    {
      key: 'name',
      title: '操作人',
      dataIndex: 'name',
      render: renderContent,
    },
    {
      key: 'type_text',
      title: '操作',
      dataIndex: 'type_text',
      render: renderContent,
    },
    {
      key: 'create_time',
      title: '操作时间',
      dataIndex: 'create_time',
      render: renderContent,
    }
  ];

  const [data, setData] = useState({
    list: [],
    num: 0,
    page: 1,
    total: 0,
  });
  const [typelist, setTypelist] = useState([{
    name: '',
    value: 0,
  }]);
  const [param, setParam] = useState({
    type: -1,
    page: 1,
    num: 10
  });
  const [loadingState, setLoadingState] = useState(false);

  const getLogListData = async () => {
    setLoadingState(true);
    try {
      const res = await getLogList({
        // 这里暂时写死
        admin_uuid: '',
        type: param.type,
        page: param.page,
        num: param.num,
      });
      const {
        logs: listData,
        page: pageCount,
        num: numCount,
        total: totalCount
      } = res.data;

      listData.map((item : any) => (Object.assign(item, {
        key: item.uuid
      })));
      setData({
        list: listData,
        page: pageCount,
        num: numCount,
        total: totalCount
      });
      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      console.log(error);
    }
  };

  const getLogTypeListData = async () => {
    try {
      const res = await getLogTypeList();
      const { types: typeList } = res.data;
      typeList.map((item: selectItem) => (Object.assign(item, {
        key: item.name
      })));
      setTypelist(typeList);
      // setParam(Object.assign(param, { type: typelist[0].value }));
      getLogListData();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLogTypeListData();
    getLogListData();
  }, []);

  function handleChange(value: number) {
    setParam(Object.assign(param, { type: value }));
    getLogListData();
  }

  function changePagination(pageCount: number | undefined, pageSize: number | undefined) {
    setParam(Object.assign(param, { page: pageCount, num: pageSize }));
    getLogListData();
  }
  return (
    <PageLayout>
      <div className="page-wrap">
        <h3>操作日志</h3>
        <div className="query-input">
          <Select placeholder="操作类型" style={{ width: 120 }} onChange={handleChange}>
            {
              typelist.map((item: selectItem) => (
                <Option key={item.value} value={item.value}>{item.name}</Option>
              ))
            }
          </Select>
        </div>
        <br />
        <Table
          rowKey="content"
          loading={loadingState}
          columns={columns}
          dataSource={data.list}
          bordered
          expandable={{
            expandedRowRender: (record: any) => <p>{record.content}</p>
          }}
          pagination={{
            onChange: changePagination,
            total: data.total,
            pageSize: 10,
          }}
        />
      </div>
    </PageLayout>
  );
};
export default Page;
