import {
  Modal, Button, Select, message
} from 'antd';
import React from 'react';
import {
  getPrivilegeList, updateAccount
} from '@/common/apis';

const { Option } = Select;

const typelist = [{
  value: 1,
  name: '管理员'
}, {
  value: 20,
  name: '分析师'
}, {
  value: 30,
  name: '渠道管理员'
}, {
  value: 40,
  name: '渠道'
}];

export default class App extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      title: '',
      roleTypeValue: '',
      accountId: '',
      privilegeList: [],
      ruleGroupId: [],
      defaultRuleGroupId: []
    };
  }

  getPrivilegeListData = async () => {
    const { roleTypeValue, ruleGroupId } = this.state;
    try {
      const res = await getPrivilegeList({
        account_type: roleTypeValue,
        page: 1,
        num: 999,
      });

      const {
        rule_groups: listData
      } = res.data;
      const newArr :any = [];
      ruleGroupId.forEach((element: any) => {
        newArr.push(element.uuid);
      });
      this.setState({
        privilegeList: listData,
        defaultRuleGroupId: newArr
      });
    } catch (error) {
      message.error(error.message);
    }
  };

  showModal = (options: any) => {
    const { account_type, uuid, rule_groups } = options.data;
    this.setState({
      visible: true,
      title: options.title || '',
      roleTypeValue: account_type,
      accountId: uuid,
      ruleGroupId: rule_groups
    }, () => {
      this.getPrivilegeListData();
    });
  };

  doUpdate = async () => {
    const { roleTypeValue, ruleGroupId, accountId } = this.state;
    try {
      await updateAccount({
        account_type: roleTypeValue,
        group_ids: ruleGroupId,
        uuid: accountId
      });
      message.success('修改成功');
      this.setState({
        visible: false,
      });
    } catch (error) {
      message.error(error.message);
    }
  }

  handleOk = () => {
    this.doUpdate();
  };

  handleCancel = (e: any) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  handleChange = (value: any) => {
    console.log(`selected ${value}`);
  }

  selectRoleChange = (value: any) => {
    console.log(`selected ${value}`);
    this.setState({
      roleTypeValue: value
    });
  }

  handleRuleChange = (value: any) => {
    this.setState({
      ruleGroupId: value
    });
  }

  render() {
    const {
      visible, title, privilegeList, defaultRuleGroupId, roleTypeValue
    } = this.state;
    const closable = true;
    return (
      <>
        <Modal
          title={title}
          visible={visible}
          closable={closable}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={[
            <Button key="submit" type="primary" onClick={this.handleOk}>
              确定
            </Button>,
          ]}
        >
          <div className="modal-input">
            <span className="label">角色配置</span>
            <Select
              placeholder="角色类型"
              style={{ width: 120 }}
              onChange={this.selectRoleChange}
              defaultValue={roleTypeValue}
            >
              {
                typelist.map((item: any) => (
                  <Option key={item.value} value={item.value}>{item.name}</Option>
                ))
              }
            </Select>
          </div>
          <div className="modal-input">
            <span className="label">数据权限配置</span>
            <Select
              placeholder="请选择权限组"
              style={{ width: 120 }}
              onChange={(e) => this.handleRuleChange(e)}
              mode="multiple"
              defaultValue={defaultRuleGroupId}
              allowClear
            >
              {
                privilegeList.map((item: any) => (
                  <Option key={item.uuid} value={item.uuid}>{item.name}</Option>
                ))
              }
            </Select>
          </div>
        </Modal>
      </>
    );
  }
}
