import React, { FC, useEffect, useState } from 'react';
import { getOverview } from '@/common/apis';
import { Spin } from 'antd';

const tagStrings: any = {
  new_user: '今日新增用户数',
  dau: '今日活跃用户数',
  yesterday_retention: '次留',
  week_retention: '7日留存',
  new_user_retention: '新用户留存',
  week_new_user: '7日新用户',
  week_dau: '7日活跃用户数',
  new_user_hour: '上一小时新增用户数'
};

const Page: FC = () => {
  const [cardData, setCardData] = useState([
    {}
  ]);
  const [loading, setLoading] = useState(false)

  const getOverviewData = async () => {
    try {
      setLoading(true);
      const { data } = await getOverview();
      const keys = Object.keys(data);
      const dataArray = keys.filter((item) => !/(_rate)/.test(item));
      const resArray: any[] = [];
      dataArray.forEach((item, index) => {
        let arrow_forward;
        if (data[`${item}_rate`] > 0) {
          arrow_forward = 'up';
        } else if (data[`${item}_rate`] === 0) {
          arrow_forward = 'stay';
        } else {
          arrow_forward = 'down';
        }

        resArray.push({
          name: tagStrings[item],
          num: data[item],
          rate: data[`${item}_rate`],
          forward: arrow_forward,
          key: index
        });
      });
      setCardData(resArray);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getOverviewData();
  }, []);

  const makeCards = () => (
    cardData.map((element: any) => (
      <div className="data-card" key={+element.key}>
        <div className="data-title">{ element.name }</div>
        <div className="data-num">{ element.num }</div>
        <div className="foot">{ element.rate }%<i className={`icon ${element.forward}`} /></div>
      </div>
    ))
  );
  return (
    <div>
      <Spin spinning={loading}>
        <div className="chartData">
          { makeCards() }
        </div>
      </Spin>
    </div>
  );
};
export default Page;
