import React, { FC, useEffect, useState } from 'react';
import PageLayout from '@/page/layout/index';
import DataPanel from '../component/stat-chart/data_panel';
import QueryPanel from './query_panel';
import DataTable from '../component/stat-chart/data_table';
import { useSelector } from 'react-redux'
import {
  message, Spin
} from 'antd';
import {
  getDateLine, getDateLineFile
} from '@/common/apis';
import {transData, transTableData} from '../component/stat-chart/data_transfer';

const Page: FC = () => {
  const privileges = useSelector((state: any) => state.privilege)
  const [chartData, setChartData] = useState({});
  const [tableData, setTableData] = useState({});
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false)
  const [queryState, setQueryState] = useState({});
  const [dataType, setDataType] = useState({});
  const [originData, setOriginData] = useState([]);

  const getDateLineData = async (query: any) => {
    setQueryState(query);
    try {
      setLoading(true);
      const res = await getDateLine(query);
      setOriginData(res.data.lines);
      setLoading(false);
    } catch (error) {
      localStorage.removeItem('stat_query');
      setLoading(false);
      message.error(error.message);
    }
  };

  const exportExcel = async () => {
    setExportLoading(true);
    try {
      const fileType = '.xlsx';
      const param = JSON.parse(JSON.stringify(queryState));
      // if (param.days.length > 1) {
      //   return;
      // }
      param.ftype = 2;
      const res = await getDateLineFile(param);
      const blobUrl = window.URL.createObjectURL(res.data);
      const filename = `${new Date().getTime()}${fileType}`;
      const aElement = document.createElement('a');
      document.body.appendChild(aElement);
      aElement.style.display = 'none';
      aElement.href = blobUrl;
      aElement.download = filename;
      aElement.click();
      document.body.removeChild(aElement);
      setExportLoading(false);
    } catch (error) {
      setExportLoading(false);
    }
  }

  const getDataTypeDict = (dataTypeData: any) => {
    let res: any = {}
    dataTypeData.forEach((data: any) => {
      res[data.id] = data.name
    });
     setDataType(res);
  }

  useEffect(() => {
    setChartData(transData(originData, dataType));
    setTableData(transTableData(originData, dataType));
  },[dataType, originData])

  return (
    <PageLayout>
      <div>
        {
          privileges[2][201] ? (
            <Spin spinning={loading}>
              <QueryPanel
                onQueryData={(query: any) => { getDateLineData(query)}}
                onGetDataTypeDict = {(dataType: any) => { getDataTypeDict(dataType)}}
              />
              <DataPanel
                data={chartData}
              />
              <DataTable
                queryData={queryState}
                chartData={chartData}
                data= {tableData}
                exportLoading={exportLoading}
                onExport={exportExcel}
              />
            </Spin>
          ) : ''
        }
      </div>
    </PageLayout>
  )
};
export default Page;
