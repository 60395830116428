import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage'; // localStorage
import reducer from './reducers';

const storageConfig = {
  key: 'root', // 必须有的
  storage: localStorage, // 缓存机制
};
const myPersistReducer = persistReducer(storageConfig, reducer);

const store = createStore(myPersistReducer, composeWithDevTools());

export const persistor = persistStore(store);

export default store;
