import context from './context';

const DEBUG_HOST = 'https://crucio-image-beta.oss-cn-beijing.aliyuncs.com/{uuid}?x-oss-process=image';
const PRODUCTION_HOST = 'https://qc.i.hecdn.com/{uuid}?x-oss-process=image';

const SUFFIX = '/format,{format}';

export const getImageURL = (imageId, imageWidth) => {
  const DEBUG_PATTERN = `${DEBUG_HOST}/resize,w_{width}${SUFFIX}`;
  const PRODUCTION_PATTERN = `${PRODUCTION_HOST}/resize,w_{width}${SUFFIX}`;
  const pattern = context.isDebug() || !context.forceInteralServer()
    ? DEBUG_PATTERN : PRODUCTION_PATTERN;

  return pattern
    .replace('{uuid}', imageId)
    .replace('{width}', imageWidth)
    .replace('{format}', 'jpg');
};

export const fullSizeImageURL = (imageId) => {
  const pattern = context.isDebug() || context.forceInteralServer()
    ? (DEBUG_HOST + SUFFIX) : (PRODUCTION_HOST + SUFFIX);

  return pattern
    .replace('{uuid}', imageId)
    .replace('{format}', 'jpg');
};
