import React, {
  useEffect, useState, useMemo
} from 'react';
import './index.scss';
import {
  Spin, Table
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons'

const Page = (props: any) => {
  const [tableColumn, setTableColumn] = useState([]);
  const [tableData, setTableData] = useState([]);
 
  const setDataColumns = (data: any, queryData: any) => {
    const columnArray = data.x || [];
    
    const renderTableData = (text: string, row: any, index: number) => {
      let measures = text ? text.toString().split('#') : [];
      let keys = Object.keys(row);
      let uniqueKey: any = [];
      keys.forEach(key => {
        uniqueKey.push(row[key]);
      })
      return <>{
        measures.map((item: any, i: number) => {
          return <p key={uniqueKey.join('') + i}>{item}</p>
        })
      }</>;
    }
    // 添加第一列表头
    const temptableColumn: any = [{
      title: '查询条件',
      key: 'name',
      width: 100,
      // fixed: 'left',
      dataIndex: 'name',
      render: (text: string, row: any, index: number) => {
        let name = text.split('#')[0]
        return <>{name}</>;
      },
      children: []
    }, {
      title: '数据类型',
      key: 'dataType',
      width: 150,
      // fixed: 'left',
      dataIndex: 'dataType',
      render: renderTableData,
    }];

    if (queryData.group && queryData.group.length > 0) {
      queryData.group.forEach((field: any, i: number) => {
        temptableColumn[0].children.push({
          title: field,
          key: 'name',
          width: 100,
          // fixed: 'left',
          dataIndex: 'name',
          render: (text: string, row: any, index: number) => {
            let fieldGroup = text.split('#')[0].split('&');
            let name = fieldGroup[i];
            return <>{name}</>;
          },
        })
      });
    }
    
    if (columnArray && columnArray.length > 0) {
      columnArray.forEach((item: any, index: number) => {
        temptableColumn.push({
          title: () => (
            <div>
              {
                item.split('/').map((ele: any) => (<p key={ele + index}>{ele}</p>))
              }
            </div>
          ),
          dataIndex: item,
          key: item,
          render: renderTableData,
        })
      });
    }
    setTableColumn(temptableColumn);
  }

  const setTableBody = (data: any, tableData: any) => {

    const tempBodyData: any = [];
    
    let names = Object.keys(tableData);

    names.forEach((name: any) => {
      const rowData: any = {};

      tableData[name].forEach((dateValue: any, i: number) => {
        let dateRow: any = [name];
        let measures = Object.keys(dateValue)
        
        measures.forEach((measure) => {
          if (dateRow[1]) {
            dateRow[1] = `${dateRow[1]}#${measure}`
          } else {
            dateRow.push(measure)
          }
          dateValue[measure].forEach((val: any, index: any) => {
            if (`${dateRow[index + 2]}` !== 'undefined') {
              dateRow[index + 2] = `${dateRow[index + 2]}#${val}`
            } else {
              dateRow.push(val);
            }
          });
        });
        tableColumn.forEach((item: any, index: number) => {

          if (rowData[item.key]) {
            rowData[item.key] += dateRow[index] ? `#${dateRow[index]}` : '0';
          } else {
            rowData[item.key] =dateRow[index] ? dateRow[index].toString() : '0';
          }
        });
      })
      tempBodyData.push(rowData);
    })
    setTableData(tempBodyData);
  }

  useEffect(() => {
    if (props.chartData) {
      setDataColumns(props.chartData, props.queryData); 
    }
  }, [props]);

  useMemo(() => {
    setTableBody(props.chartData, props.data);
  }, [tableColumn])
  return (
    <div className="chart-data-table">
      <div className="chartFuncBtn">
        <Spin spinning={props.exportLoading}>
          <DownloadOutlined />
          <span  onClick={() => props.onExport()}>导出Excel</span>
        </Spin>
      </div>
      <div className="table-content">
        <Table
          dataSource={tableData}
          columns={tableColumn}
          pagination={false}
          rowClassName={(record, index) => {
                        let className = 'light-row';
                        if (index % 2 === 1) className = 'dark-row';
                        return className;
                    }}
        />
      </div>
    </div>
  );
};
export default Page;
