export function getDay(day) {
  function doHandleMonth(month) {
    let m = month;
    if (month.toString().length === 1) {
      m = `0${month}`;
    }
    return m;
  }
  const today = new Date();
  const targetday_milliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetday_milliseconds);
  const tYear = today.getFullYear();
  let tMonth = today.getMonth();
  let tDate = today.getDate();
  tMonth = doHandleMonth(tMonth + 1);
  tDate = doHandleMonth(tDate);
  return `${tYear}-${tMonth}-${tDate}`;
}

export function a() {}
