import apiClient, { API_HOST, crucioOtherClient } from './request';

export const getQrImg = `//${API_HOST}/api/v1/sso/qr_img`;

// 登出
export const logout = () => (
  apiClient.post('/v1/sso/logout')
);

// 登录
export const loginWithPwd = (param) => (
  apiClient.post('/v1/sso/login_pwd', param)
);

// 修改密码
export const changePwd = (param) => (
  apiClient.post('/v1/account/change_pwd', param)
);

// 扫码登录
export const getQrInfo = (param) => (
  apiClient.get('/v1/sso/qr_info', param)
);

// 循环检查登录情况
export const loopValid = (param) => (
  apiClient.post('/v1/sso/loop_valid', param)
);

// 获取日志列表数据
export const getLogList = (params) => (
  apiClient.get('/v1/oplog/', { params })
);

// 获取日志列表类型筛选项
export const getLogTypeList = () => (
  apiClient.get('/v1/oplog/type_list')
);

// 获取日志列表数据
export const getUserList = (param) => (
  apiClient.get('/v1/user/list', param)
);

// 根据账号类型分页获取账号列表
export const getAcountList = (params) => (
  apiClient.get('/v1/account/', { params })
);

// 添加内部账号
export const addAccount = (params) => (
  apiClient.post('/v1/account/', params)
);

// 添加渠道账号
export const addMarketAccount = (params) => (
  apiClient.post('/v1/account/add_market_account', params)
);


// 修改内部账号
export const updateAccount = (params) => (
  apiClient.put(`/v1/account/${params.uuid}`, params)
);

// 获取折线数据
export const getDateLine = (params) => (
  apiClient.post('/v1/realstats/dateline', params)
);

// 获取顶层数据
export const getOverview = (params) => (
  apiClient.get('/v1/realstats/overview', params)
);

// 获取渠道列表
export const getMarketList = (params) => (
  apiClient.get('/v1/market/', { params })
);

// 获取三级渠道列表
export const getMarketPlan = (params) => (
  apiClient.get('/v1/market/plan', { params })
);

// 获取折线数据文件
export const getDateLineFile = (params) => (
  crucioOtherClient.post('/v1/realstats/dateline', params)
);

// export const getDateLineUrl = (params) => {
//   const keys = Object.keys(params);
//   const url = '/api/v1/realstats/dateline';
//   const queryArray = [];
//   keys.forEach((key) => {
//     queryArray.push(`${key}=${params.key || ''}`);
//   });
//   return `${url}?${queryArray.join('&')}`;
// };

// 获取权限组
export const getPrivilegeList = (params) => (
  apiClient.get('/v1/account/rule_group', { params })
);

// 根据父级获取权限组
export const getRuleList = (params) => (
  apiClient.get('/v1/account/rule', { params })
);

// 获取全部权限关系
export const getRuleMap = (params) => (
  apiClient.get('/v1/account/rule_map', { params })
);

// 删除权限组
export const deleteRuleGroup = (params) => (
  apiClient.delete(`/v1/account/rule_group/${params.uuid}`)
);

// 新增权限组
export const addRuleGroup = (params) => (
  apiClient.post('/v1/account/rule_group', params)
);

// 更新权限组
export const updateRuleGroup = (params) => (
  apiClient.put(`/v1/account/rule_group/${params.item_uuid}`, params)
);

// 获取部门
export const getDepartmentList = () => (
  apiClient.get('/v1/account/department')
);

// 获取部门用户
export const getDepartmentMemberList = (params) => (
  apiClient.get(`/v1/account/department/${params.id}/member`)
);

// 删除用户
export const delAccount = (params) => (
  apiClient.delete(`/v1/account/${params.id}`)
);

// 获取筛选条件
export const getStatsParam = (params) => (
  apiClient.get('/v1/realstats/params', { params })
);

// 获取二级渠道列表
// export const getTraceItemList = (params) => (
//   apiClient.get('/v1/market/trace_item', { params })
// );

// 获取item搜索结果
export const getTraceItemList = (params) => (
  apiClient.get('/v1/market/trace_item_search', { params })
);

// 获取汇总类型
export const getStatDataType = (params) => (
  apiClient.get('/v1/realstats/data_type')
);

// 获取广告汇总类型
export const getAdDataType = (params) => (
  apiClient.get('/v1/ad/data_type')
);

// 获取广告筛选条件
export const getAdParam = (params) => (
  apiClient.get('/v1/ad/params', { params })
);

// 条件搜索联想结果
export const getAdItemList = (params) => (
  apiClient.post('/v1/ad/param_search', params)
);

// 获取广告折线数据
export const getAdDateLine = (params) => (
  apiClient.post('/v1/ad/dateline', params)
);

// 获取折线数据文件
export const getAdDateLineFile = (params) => (
  crucioOtherClient.post('/v1/ad/dateline', params)
);

// 获取AD顶层数据
export const getAdOverview = (params) => (
  apiClient.post('/v1/ad/overview', params)
);
