import React, {
  useEffect, useState
} from 'react';
import './index.scss';
import { getDay } from '@/common/uitl';
import {
  DatePicker, Select, Button, Radio
} from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import {
  getStatsParam, getTraceItemList, getMarketPlan, getStatDataType
} from '@/common/apis';
import moment from 'moment';

const { RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = 'YYYY-MM-DD';

const Page = (props: any) => {

  // 缓存查询内容
  const setQueryContentToSession = () => {
    const session = {
      conditionOptions: conditionOptions,
      typeOptions: typeOptions,
      traceItemOptions: traceItemOptions,
      tracePlanOptions: tracePlanOptions,
      conditionSelectList: conditionSelectList,
      queryState: queryState
    };
    window.localStorage.setItem('stat_query', JSON.stringify(session));
  };

  // 获取缓存中查询内容
  const getQueryContentFromSession = () => {
    let data = window.localStorage.getItem('stat_query');

    if (data) {
      data = JSON.parse(data);
      const {
        conditionOptions: sconditionOptions,
        conditionSelectList: sconditionSelectList,
        queryState: squeryState,
        traceItemOptions: straceItemOptions,
        tracePlanOptions: stracePlanOptions,
        typeOptions: stypeOptions,
      }: any = data;
      setConditionOptions(sconditionOptions);
      setConditionSelectList(sconditionSelectList);
      setTraceItemOptions(straceItemOptions);
      setTracePlanOptions(stracePlanOptions);
      setTypeOptions(stypeOptions);

      setDateGroup(squeryState.days);

      return squeryState;
    } else {
      return {
        days: [{
          start_day: getDay(-6),
          end_day: getDay(0),
          limit: '',
        }],
        measures: [],
        dtype: 1,
        ftype: 0,
        condition: [],
        group: [],
        condition_op: 'and'
      };
    }
  }

  const [conditionOptions, setConditionOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [traceItemOptions, setTraceItemOptions] = useState([]);
  const [tracePlanOptions, setTracePlanOptions] = useState([]);

  type commonOptionsType = {
    [key: string]: any[]
  }

  const [commonOptions, setCommonOptions] = useState(() => {
    const ret: commonOptionsType = {};
    return ret;
  });

  const [conditionSelectList, setConditionSelectList] = useState([
    {
      index: 0,
      cselect: '',
      cinput: '',
    }
  ]);
  const [dateGroup, setDateGroup] = useState([{
    start_day: getDay(-6),
    end_day: getDay(0),
    limit: '',
  }]);
  const [queryState, setQueryState] = useState(() => {
    return getQueryContentFromSession();
  });

  // 获取查询条件列表
  const getDataType = async () => {
    try {
      const res = await getStatDataType();
      const { data_type } = res.data;
      
      setTypeOptions(data_type);
      props.onGetDataTypeDict(data_type);
      getDateLineData();
    } catch (error) {
      console.log(error.message);
    }
  };

  // 获取查询条件列表
  const getConditionListData = () => {
    try {
      let resArray: any = [];
      let fianalParams: any = [];
      queryState.measures.forEach((element: any) => {
        let promise = getStatsParam({
          app_id: 1,
          measure: element,
        });
        resArray.push(promise);
      });

      Promise.all(resArray).then((res) => {
        if (res && res.length > 0) {
          res.forEach((item: any) => {
            let paramArr = item.data.params;
            fianalParams = fianalParams.concat(paramArr);
          });
          fianalParams = resArray.length > 1 ? fianalParams.filter((item: any, index: number) => (fianalParams.indexOf(item) !== index)) : fianalParams;
          setConditionSelectList([{
            index: 0,
            cselect: '',
            cinput: '',
          }]);
          setConditionOptions(fianalParams);
        }
      });

    } catch (error) {
      console.log(error.message);
    }
  };

  // 获取trace_plan查询列表
  const getMarketPlanData = async (value: any) => {
    try {
      const res = await getMarketPlan({
        plan: value
      });
      setTracePlanOptions(res.data.plans);
    } catch (error) {
      console.log(error.message);
    }
  };

  // 获取trace_item_id查询列表
  const getTraceItemListData = async (value: any) => {
    try {
      const res = await getTraceItemList({
        trace_item: value
      });
      setTraceItemOptions(res.data.items);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCommonListData = (field: string, value: any) => {
    try {
      setCommonOptions(prevStats => ({
        ...prevStats,
        [field]: [value]
      }));
    } catch (error) {
      console.log(error.message);
    }
  };

  // 组装type下拉菜单选项
  const typeSelect = () => {
    return (
      typeOptions.map((item: any) => (
        <Option key={item.id} value={item.id}>{item.name}</Option>
      ))
    );
  };

  // 组装条件下拉菜单选项
  const conditionSelect = () => {
    return (
      conditionOptions.map((item: string) => (
        <Option key={item} value={item}>{item}</Option>
      ))
    );
  };

  // 组装trace_item下拉菜单选项
  const traceItemSelect = () => {
    return (
      traceItemOptions.map((item: any) => (
        <Option key={item.uuid} value={item.uuid}>{item.name}</Option>
      ))
    );
  };

  // 组装trace_plan下拉菜单选项
  const tracePlanSelect = () => {
    return (
      tracePlanOptions.map((item: any) => (
        <Option key={item} value={item}>{item}</Option>
      ))
    );
  };

  const commonSelect = (field: string) => {
    if(commonOptions[field]){
      return (
          commonOptions[field].map((item: any) => (
            <Option key={item} value={item}>{item}</Option>
          ))
      )
    }
  }

  // 日期选择变更时间
  const onDateChange = (date: any, dateStrings: any, index: any) => {
    queryState.days[index] = { start_day: dateStrings[0], end_day: dateStrings[1] };
    // setQueryState(Object.assign(queryState, queryState.days));
  };

  // 查询按钮
  const getDateLineData = () => {
    const conditionList: any = [];
    conditionSelectList.forEach((item) => {
      conditionList.push({
        field: item.cselect,
        value: item.cinput,
        op: 'equal',
      });
    });
    const param = Object.assign(queryState, {condition: conditionList});
    setQueryContentToSession();
    props.onQueryData(param);
  }

  // 增加查询条件
  const addCondition = () => {
    conditionSelectList.push({
      index: conditionSelectList.length,
      cselect: '',
      cinput: '',
    });
    setConditionSelectList([...conditionSelectList]);
  }

  // 删除查询条件
  const removeCondition = (index: any) => {
    conditionSelectList.splice(index, 1);
    conditionSelectList.forEach((item, i) => {
      item.index = i;
    });
    if (conditionSelectList.length === 1) {
      queryState.condition_op = '';
    }
    setConditionSelectList([...conditionSelectList]);
  }

  // 查询条件变更
  const handleConditionChange = (value: any, index: number) => {
    conditionSelectList[index].cselect = value;
    setConditionSelectList([...conditionSelectList]);
  }

  // 查询条件关系变更
  const handleConditionOpChange = (e: any) => {
    setQueryState(Object.assign(queryState, {condition_op: e.target.value }));
  }

  // 条件输入框模糊查询
  const handleConditionInput = (data: any, index: number) => {
    conditionSelectList[index].cinput = data;
    setConditionSelectList([...conditionSelectList]);
  }

  // 组装条件查询模块
  const makeConditionSelectList = (item: any) => {
    const conditionItem = (
      <div key={item.index}>
        <Select
          className="query-select"
          defaultValue={conditionSelectList[item.index].cselect}
          value={conditionSelectList[item.index].cselect}
          style={{ width: 200 }}
          allowClear={true}
          defaultActiveFirstOption={true}
          onChange={(value: any) => handleConditionChange(value, item.index)}
        >
          {conditionSelect()}
        </Select>
        <span>等于　</span>
        <Select
          showSearch
          mode="multiple"
          style={{ width: 200}}
          placeholder=""
          showArrow={false}
          allowClear={Boolean(true)}
          defaultValue={conditionSelectList[item.index].cinput || []}
          value={conditionSelectList[item.index].cinput || []}
          notFoundContent={null}
          filterOption={false}
          onSearch={(value: any) => {
            if (conditionSelectList[item.index].cselect === 'trace_plan') {
              getMarketPlanData(value);
            } else if (conditionSelectList[item.index].cselect === 'trace_item_id') {
              getTraceItemListData(value);
            } else{
              getCommonListData(conditionSelectList[item.index].cselect, value);
            }
          }}
          onChange={(value: any) => {handleConditionInput(value, item.index)}}
        >
          {
            conditionSelectList[item.index].cselect === 'trace_item_id' ?
              traceItemSelect() :
            conditionSelectList[item.index].cselect === 'trace_plan' ? 
              tracePlanSelect() : commonSelect(conditionSelectList[item.index].cselect)
          }
        </Select>
        &nbsp;&nbsp;
        {
          conditionSelectList.length > 1 ?
          <Button
            type="primary"
            shape="circle"
            icon={<MinusCircleOutlined />}
            onClick={() => removeCondition(item.index)}
          />
          : ''
        }
      </div>
    );
    
    return conditionItem;
  }

  const handleStypeChange = (value: any) => {
    setQueryState(Object.assign(queryState, { measures: value }));
    getConditionListData()
  };

  const handleDtypeChange = (value: any) => {
    setQueryState(Object.assign(queryState, { dtype: value }));
  };

  const handleGroupChange = (value: any) => {
    setQueryState(Object.assign(queryState, { group: value }))
  };

  const addCompareGroup = () => {
    let dateArray = JSON.parse(JSON.stringify(queryState.days));
    dateArray.push({
      start_day: getDay(-6),
      end_day: getDay(0),
      limit: '',
    })
    setQueryState(Object.assign(queryState, {days: dateArray}));
    dateGroup.push({
      start_day: getDay(-6),
      end_day: getDay(0),
      limit: '',
    })
    setDateGroup([...dateGroup]);
  }

  const removeDatePicker = (index: any) => {
    let dateArray = JSON.parse(JSON.stringify(queryState.days));
    dateArray.splice(index, 1);
    dateGroup.splice(index, 1);
    setQueryState(Object.assign(queryState, {days: dateArray}));
    setDateGroup([...dateGroup]);
  }

  useEffect(() => {
    getDataType();
    // getConditionListData();
  }, []);

  return (
    <div className="queryPanel">
      <div>
        <Select
          className="query-select"
          style={{ width: 200 }}
          allowClear={true}
          mode="multiple"
          defaultValue={queryState.measures}
          onChange={handleStypeChange}
        >
          {typeSelect()}
        </Select>
      </div>
      
      <div className="conditionGroup">
        <div
          className="conditionSelect"
          style={{borderLeft: conditionSelectList.length > 1 ? '1px dashed #333333' : ''}}
        >
        { 
          conditionSelectList.map((item: any) => (
            makeConditionSelectList(item)
          ))
        }
        {
          conditionSelectList.length > 1 ?
          <Radio.Group
            style={{marginLeft: 20}}
            defaultValue={queryState.condition_op}
            size="small"
            className="radio"
            onChange={handleConditionOpChange}
          >
            <Radio.Button style={{display: 'block'}} value="and">且</Radio.Button>
            <Radio.Button style={{display: 'block'}} value="or">或</Radio.Button>
          </Radio.Group> : ''
        }
        </div>
        <Button type="primary" style={{marginLeft: 20}} shape="circle" icon={<PlusCircleOutlined />} onClick={addCondition} />
      </div>
      <br />
      <div>
        按 <Select
          className="query-select"
          style={{ width: 200 }}
          defaultActiveFirstOption={true}
          mode="multiple"
          allowClear={true}
          defaultValue={queryState.group}
          onChange={(value: any) => handleGroupChange(value)}
        >
          {conditionSelect()}
        </Select> 聚合
      </div>
      <div>
        <Select
          className="query-select"
          defaultValue={queryState.dtype.toString()}
          style={{ width: 80 }}
          allowClear={true}
          onChange={handleDtypeChange}
        >
          <Option value="1">按天</Option>
          <Option value="2">按小时</Option>
        </Select>
        {
          dateGroup.map((item: any, index: any) => {
            if (index === 0) {
              return (
                <RangePicker
                  key={index}
                  style={{ width: 280 }}
                  format={dateFormat}
                  onChange={(date, dateStrings) => onDateChange(date, dateStrings, index)}
                  allowClear={false}
                  defaultValue={[moment(queryState.days[0].start_day, dateFormat), moment(queryState.days[0].end_day, dateFormat)]}
                />
              )
            } else {
              return (
                <span key={index}><RangePicker
                  style={{ width: 280 }}
                  format={dateFormat}
                  onChange={(date, dateStrings) => onDateChange(date, dateStrings, index)}
                  allowClear={false}
                  disabled={[false, false]}
                  defaultValue={[moment(queryState.days[index].start_day || '', dateFormat), moment(queryState.days[index].end_day || '', dateFormat)]} />
                  <CloseCircleOutlined onClick={() => removeDatePicker(index)} />&nbsp;&nbsp;</span>
              )
            }
          })
        }
        <Button
          key="addCompare"
          type="link"
          onClick={addCompareGroup}
        >
          添加对比
        </Button>
        <Button key="submit" type="primary" onClick={getDateLineData}>
          查询
        </Button>
      </div>
    </div>
  );
};
export default Page;
