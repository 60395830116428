import React, {
  FC, useEffect, useState, useRef
} from 'react';
import {
  Table, Select, Space, Button, message
} from 'antd';
import { getAcountList, delAccount } from '@/common/apis';
import PageLayout from '@/page/layout/index';
import UpdateRuleModal from './updateRoleModal';
import AddAccountModal from './addAccountModal';
import './index.scss';

const { Option } = Select;
interface selectItem {
  name: string,
  value: number
}

const typelist = [{
  value: 1,
  name: '管理员'
}, {
  value: 20,
  name: '分析师'
}, {
  value: 30,
  name: '渠道管理员'
}, {
  value: 40,
  name: '渠道'
}];

const Page: FC = () => {
  const modalRef: any = useRef();
  const accountModalRef: any = useRef();

  const [data, setData] = useState({
    list: [],
    num: 0,
    page: 1,
    total: 0,
  });
  const [param, setParam] = useState({
    type: -1,
    page: 1,
    num: 10
  });
  const [loadingState, setLoadingState] = useState(false);

  const getAccountListData = async () => {
    setLoadingState(true);
    try {
      const res = await getAcountList({
        account_type: param.type,
        page: param.page,
        num: param.num,
      });
      const {
        accounts: listData,
        page: pageCount,
        num: numCount,
        total: totalCount
      } = res.data;

      listData.map((item : any) => (Object.assign(item, {
        key: item.uuid
      })));
      setData({
        list: listData,
        page: pageCount,
        num: numCount,
        total: totalCount
      });

      setLoadingState(false);
    } catch (error) {
      setLoadingState(false);
      console.log(error);
    }
  };

  function showUpdateModal(item: any) {
    const modalNode = modalRef.current;
    if (modalNode !== null) {
      modalNode.showModal({
        title: '账户权限设置',
        data: item
      });
    }
  }

  async function deleteAccount(item: any) {
    try {
      await delAccount({
        id: item.uuid
      });
      message.success('删除成功');
      getAccountListData();
    } catch (error) {
      message.error(error.message);
    }
  }

  const renderContent = (value: any) => {
    const obj = {
      children: value || '--',
      props: {},
    };
    return obj;
  };

  const columns = [
    {
      title: 'UUID',
      dataIndex: 'uuid',
      render: renderContent,
    },
    {
      title: '昵称',
      dataIndex: 'name',
      render: renderContent,
    },
    {
      title: '角色',
      dataIndex: 'account_type_text',
      render: renderContent,
    },
    {
      title: '喵牌',
      dataIndex: 'invitecode',
      render: renderContent,
    },
    {
      title: '数据权限',
      dataIndex: 'rule_groups',
      render: (value: any) => {
        let ruleStr = '';
        value.forEach((item: any) => {
          ruleStr = `${ruleStr}${ruleStr !== '' ? ',' : ''}${item.name}`;
        });
        return ruleStr;
      },
    },
    {
      title: '操作',
      dataIndex: 'opt',
      render: (text: any, record: any) => (
        <Space size="middle">
          <a onClick={() => (showUpdateModal(record))}>修改用户</a>
          <a onClick={() => (deleteAccount(record))}>删除</a>
        </Space>
      )
    }
  ];

  useEffect(() => {
    getAccountListData();
  }, []);

  function changePagination(pageCount: number | undefined, pageSize: number | undefined) {
    setParam(Object.assign(param, { page: pageCount, num: pageSize }));
    getAccountListData();
  }

  function handleChange(value: number) {
    setParam(Object.assign(param, { type: value }));
    getAccountListData();
  }

  function showAddAccountModal() {
    const modalNode = accountModalRef.current;
    if (modalNode !== null) {
      modalNode.showModal({
        title: '添加用户',
      });
    }
  }

  return (
    <PageLayout>
      <div className="page-wrap">
        <h3>用户列表</h3>
        <div className="query-input">
          <Button type="primary" onClick={() => { showAddAccountModal(); }}>添加用户</Button>
          <Select placeholder="角色类型" style={{ width: 120 }} onChange={handleChange}>
            {
              typelist.map((item: selectItem) => (
                <Option key={item.value} value={item.value}>{item.name}</Option>
              ))
            }
          </Select>
        </div>
        <br />
        <Table
          rowKey="uuid"
          loading={loadingState}
          columns={columns}
          dataSource={data.list}
          bordered
          pagination={{
            onChange: changePagination,
            total: data.total,
            pageSize: 10,
          }}
        />
        <UpdateRuleModal ref={modalRef} onRefresh={getAccountListData} />
        <AddAccountModal ref={accountModalRef} onRefresh={getAccountListData} />
      </div>
    </PageLayout>
  );
};
export default Page;
