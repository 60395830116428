import React, {
  useEffect, useRef, useState
} from 'react';
import * as echarts from 'echarts';
import {
  Spin
} from 'antd';
import { lineChart, chartTheme } from './chart_options';

import './index.scss';

const Page = (props: any) => {
  const echatsPanel = useRef(null);
  const [chartLoading, setChartLoading] = useState(false);
  const [chartName, setChartName] = useState('');

  const paintChart = (chatOptions: any) => {
    setChartLoading(true);
    const chartNode = echatsPanel.current;
    if (chartNode) {
      echarts.registerTheme('crucio', chartTheme);
      const myChart = echarts.init(chartNode, 'crucio');
      // @ts-ignore
      myChart.setOption(chatOptions, true, true);
      setTimeout(() => {
        myChart.resize();
      },200);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    }
    setChartLoading(false);
  }

  const init = () => {
    const chatOptions: any = JSON.parse(JSON.stringify(lineChart));
    chatOptions.series = [];


    const { x, y } = props.data;
    // 横坐标
    chatOptions.xAxis.data = x;

    if (y && y.length > 0) {
      y.forEach((element: any, index: any) => {
        let keys = Object.keys(element);
        // 图例
        chatOptions['legend'].data.push(...keys);
        chatOptions.series.push({
          name: keys[0],
          data:  element[keys[0]],
          type: 'line',
        });
      });
    }
    paintChart(chatOptions);
  };

  useEffect(() => {
    if (props.data) {
      init();
    }
  });

  return (
    <div className="content-bk">
      <div className="chartComponent">
        <div className="chartPanel">
          <div className="panelTitle">{chartName}</div>
          <Spin spinning={chartLoading}>
            <div ref={echatsPanel} className="chart" />
          </Spin>
        </div>
      </div>
    </div>
  );
};
export default Page;
