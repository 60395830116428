import React, { useEffect, useState } from 'react';

const tagStrings: any = {
  money: '广告收入',
  cpm: '广告cpm',
  ctr: '广告ctr',
  req_cnt: '广告请求量',
  resp_cnt: '广告返回量',
  pv: '广告展示量',
  respr: '广告填充率',
  reqr: '广告展示率',
  sys_respr: '内部填充率',
  sys_ctr: '内部点击率',
};

const Page = (props: any) => {
  const [cardData, setCardData] = useState([]);

  const getOverviewData = (data: any) => {
    try {
      if (Object.keys(data).length < 1 ) {
        return;
      }
      
      const keys = Object.keys(data);
      const dataArray = keys.filter((item) => !/(_rate)/.test(item));
      const resArray: any = [];
      
      // 按照已知顺序遍历
      let tagKeys = Object.keys(tagStrings);

      tagKeys.forEach((item, index) => {
        if (data[item]) {
          let arrow_forward;
          if (data[`${item}_rate`] > 0) {
            arrow_forward = 'up';
          } else if (data[`${item}_rate`] === 0) {
            arrow_forward = 'stay';
          } else {
            arrow_forward = 'down';
          }

          resArray.push({
            name: tagStrings[item],
            num: data[item],
            rate: data[`${item}_rate`],
            forward: arrow_forward,
            key: index,
            id: item
          });
        }
      });
      setCardData(resArray);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOverviewData(props.data);
  }, [props]);

  const makeCards = () => (
    cardData.map((element: any) => (
      <div className="data-card" key={+element.key}>
        <div className="label">{ element.name }</div>
        <div className="countnum">{ element.num.toLocaleString() }
          {
            ['sys_respr', 'ctr', 'reqr', 'respr'].includes( element.id ) ? '%' :
            ['cpm', 'money'].includes(element.id) ? '元' : ''
          }
        </div>
        {
          element.rate ? <div className="foot">{ element.rate }%<i className={`icon ${element.forward}`} /></div> : ''
        }
      </div>
    ))
  );

  return (
    <div className="cardPanel">
      { makeCards() }
    </div>
  );
};
export default Page;
