import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useSelector } from 'react-redux';

import {
  BrowserRouter as Router,
  Link,
  withRouter
} from 'react-router-dom';
import routes from '@/router/index';

const Siders = (history: any) => {
  const data = useSelector((state: any) => state);
  const [state] = useState({
    collapsed: false,
    selectedKey: history.location.pathname,
    privileges: Object.keys(data.privilege)
  });
  const { Sider } = Layout;

  useEffect(() => {});

  const menuClick = (
    {
      item, key, keyPath, domEvent,
    }: any
  ): void => {
    window.location.href = keyPath;
  };

  const makeSider = () => {
    const siderBarMenu = [];
    const privilSider = routes.filter(() => true);

    const getItemIcon = (item: any) => (
      <div className={`${item.icon} icon`} />
    );

    const getMenuItemList = (item: any) => {
      if (state.privileges.includes(item.privilege)) {
        return (
          <Menu.Item key={item.path} icon={getItemIcon(item)}>
            <Link to={item.path}>{item.name}</Link>
          </Menu.Item>
        );
      }
      return null;
    };

    siderBarMenu.push(privilSider.map((item: any) => {
      if (item.children && item.children.length > 0) {
        const childrenList = item.children.map((it: any) => getMenuItemList(it));
        return (
          <Menu.ItemGroup key={item.path} title={item.title}>
            {childrenList}
          </Menu.ItemGroup>
        );
      }
      return getMenuItemList(item);
    }));
    return siderBarMenu;
  };

  return (
    <Router>
      <Sider>
        <div className="sider-logo" />
        <Menu theme="dark" selectedKeys={[state.selectedKey]} mode="inline" onClick={menuClick}>
          {
            makeSider()
          }
        </Menu>
      </Sider>
    </Router>
  );
};

const SiderNavRouter = withRouter(Siders);
export default SiderNavRouter;
